import React from "react";
import loader from "../../assets/img/icons/brook-preloader.gif";

export default function Loader() {
  return (
    <div className="preloader-wrap">
      <img src={loader} alt="loading..." />
    </div>
  );
}
