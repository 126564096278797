import React, { useEffect, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./helper/scrollToTop";
import WOW from "wowjs";
import Loader from "./container/loader/index";
const BlogDetailsNoSidebar = React.lazy(() =>
  import("./pages/blog/BlogDetailsNoSidebar")
);
const BlogDetails = React.lazy(() => import("./pages/blog/BlogDetails"));
const BlogRightSidebar = React.lazy(() =>
  import("./pages/blog/BlogRightSidebar")
);
const BlogLeftSidebar = React.lazy(() =>
  import("./pages/blog/BlogLeftSidebar")
);
const BlogWithoutSidebar = React.lazy(() =>
  import("./pages/blog/BlogWithoutSidebar")
);
const PortfolioDetailsModern = React.lazy(() =>
  import("./pages/portfolios/PortfolioDetailsModern")
);
const PortfolioDetailsRight = React.lazy(() =>
  import("./pages/portfolios/PortfolioDetailsRight")
);
const PortfolioDetailsLeft = React.lazy(() =>
  import("./pages/portfolios/PortfolioDetailsLeft")
);
const PortfolioFilterable = React.lazy(() =>
  import("./pages/portfolios/PortfolioFilterable")
);
const PortfolioAjaxLoading = React.lazy(() =>
  import("./pages/portfolios/PortfolioAjaxLoading")
);
const PortfolioMasonryWide = React.lazy(() =>
  import("./pages/portfolios/PortfolioMasonryWide")
);
const PortfolioMasonryBoxed = React.lazy(() =>
  import("./pages/portfolios/PortfolioMasonryBoxed")
);
const PortfolioMetroBoxed = React.lazy(() =>
  import("./pages/portfolios/PortfolioMetroBoxed")
);
const PortfolioMetroWide = React.lazy(() =>
  import("./pages/portfolios/PortfolioMetroWide")
);
const PortfolioWithCaption = React.lazy(() =>
  import("./pages/portfolios/PortfolioWithCaption")
);
const PortfolioGridWide = React.lazy(() =>
  import("./pages/portfolios/PortfolioGridWide")
);
const PortfolioGridBoxed = React.lazy(() =>
  import("./pages/portfolios/PortfolioGridBoxed")
);
const Error = React.lazy(() => import("./pages/others/Error"));
const Career = React.lazy(() => import("./pages/others/Career"));
const ComingSoon = React.lazy(() => import("./pages/others/ComingSoon"));
const Maintenance = React.lazy(() => import("./pages/others/Maintenance"));
const TeamCarousel = React.lazy(() => import("./pages/team/TeamCarousel"));
const TeamGrid = React.lazy(() => import("./pages/team/TeamGrid"));
const PricingTableTwo = React.lazy(() =>
  import("./pages/pricing/PricingTableTwo")
);
const PricingTableOne = React.lazy(() =>
  import("./pages/pricing/PricingTableOne")
);
const ContactClassic = React.lazy(() =>
  import("./pages/contact/ContactClassic")
);
const Sitemap = React.lazy(() => import("./pages/sitemap/Sitemap"));
const ThankYou = React.lazy(() => import("./pages/thankyou/ThankYou"));
const ServiceModern = React.lazy(() => import("./pages/service/ServiceModern"));
const ServiceClassic = React.lazy(() =>
  import("./pages/service/ServiceClassic")
);
const AboutPageFour = React.lazy(() => import("./pages/about/AboutPageFour"));
const AboutPageThree = React.lazy(() => import("./pages/about/AboutPageThree"));
const AboutPageTwo = React.lazy(() => import("./pages/about/AboutPageTwo"));
const AboutPageOne = React.lazy(() => import("./pages/about/AboutPageOne"));
const AboutIM = React.lazy(() => import("./pages/about/AboutIM"));
const BrandPage = React.lazy(() => import("./pages/brands/brands"));
const InfluencerPage = React.lazy(() =>
  import("./pages/influencers/Influencers")
);
const JoinForm = React.lazy(() => import("./pages/influencers/JoinForm"));
const ElementList = React.lazy(() => import("./pages/elements/ElementList"));
const ElementTypedText = React.lazy(() =>
  import("./pages/elements/ElementTypedText")
);
const ElementButton = React.lazy(() =>
  import("./pages/elements/ElementButton")
);
const ElementAccordion = React.lazy(() =>
  import("./pages/elements/ElementAccordion")
);
const ElementMessageBox = React.lazy(() =>
  import("./pages/elements/ElementMessageBox")
);
const ElementPopupVideo = React.lazy(() =>
  import("./pages/elements/ElementPopupVideo")
);
const ElementGallery = React.lazy(() =>
  import("./pages/elements/ElementGallery")
);
const ElementLightweightSlider = React.lazy(() =>
  import("./pages/elements/ElementLightweightSlider")
);
const ElementGradation = React.lazy(() =>
  import("./pages/elements/ElementGradation")
);
const ElementSocialIcons = React.lazy(() =>
  import("./pages/elements/ElementSocialIcons")
);
const ElementMaps = React.lazy(() => import("./pages/elements/ElementMaps"));
const ElementForms = React.lazy(() => import("./pages/elements/ElementForms"));
const ElementTabs = React.lazy(() => import("./pages/elements/ElementTabs"));
const ElementTestimonial = React.lazy(() =>
  import("./pages/elements/ElementTestimonial")
);
const ElementIconBox = React.lazy(() =>
  import("./pages/elements/ElementIconBox")
);
const ElementProgressbar = React.lazy(() =>
  import("./pages/elements/ElementProgressbar")
);
const ElementCounter = React.lazy(() =>
  import("./pages/elements/ElementCounter")
);
const ElementCountdownClock = React.lazy(() =>
  import("./pages/elements/ElementCountdownClock")
);
const ElementInstagramFeed = React.lazy(() =>
  import("./pages/elements/ElementInstagramFeed")
);
const ElementCallToAction = React.lazy(() =>
  import("./pages/elements/ElementCallToAction")
);
const ElementPricingTable = React.lazy(() =>
  import("./pages/elements/ElementPricingTable")
);
const ElementTeamMember = React.lazy(() =>
  import("./pages/elements/ElementTeamMember")
);
const ElementCarouselSlider = React.lazy(() =>
  import("./pages/elements/ElementCarouselSlider")
);
const ElementFlexibleImageSlider = React.lazy(() =>
  import("./pages/elements/ElementFlexibleImageSlider")
);
const ElementClientLogo = React.lazy(() =>
  import("./pages/elements/ElementClientLogo")
);
const HomeVerticalSlidePortfolio = React.lazy(() =>
  import("./pages/homes/HomeVerticalSlidePortfolio")
);
const HomeMinimalPortfolio = React.lazy(() =>
  import("./pages/homes/HomeMinimalPortfolio")
);
const HomeCreativePortfolio = React.lazy(() =>
  import("./pages/homes/HomeCreativePortfolio")
);
const HomeRevolutionary = React.lazy(() =>
  import("./pages/homes/HomeRevolutionary")
);
const HomeAuthenticStudio = React.lazy(() =>
  import("./pages/homes/HomeAuthenticStudio")
);
const HomeFreelancer = React.lazy(() => import("./pages/homes/HomeFreelancer"));
const HomeMinimalAgency = React.lazy(() =>
  import("./pages/homes/HomeMinimalAgency")
);
const HomeArchitecture = React.lazy(() =>
  import("./pages/homes/HomeArchitecture")
);
const HomeDesignStudio = React.lazy(() =>
  import("./pages/homes/HomeDesignStudio")
);
const HomeDigitalAgency = React.lazy(() =>
  import("./pages/homes/HomeDigitalAgency")
);
const HomeCreativeAgency = React.lazy(() =>
  import("./pages/homes/HomeCreativeAgency")
);
const HomeBusiness = React.lazy(() => import("./pages/homes/HomeBusiness"));
const PrivacyPolicy = React.lazy(() => import("./pages/privacypolicy"));

const App = () => {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);
  return (
    <Router>
      <ScrollToTop>
        <Suspense fallback={Loader}>
          <Switch>
            <Route
              path={`${process.env.PUBLIC_URL + "/blog-details-no-sidebar"}`}
              component={BlogDetailsNoSidebar}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/blog-details/:id"}`}
              component={BlogDetails}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/blogs"}`}
              component={BlogRightSidebar}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/blog-left-sidebar"}`}
              component={BlogLeftSidebar}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/blog"}`}
              component={BlogWithoutSidebar}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/portfolio-details-modern"}`}
              component={PortfolioDetailsModern}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/portfolio-right-details"}`}
              component={PortfolioDetailsRight}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/portfolio-details"}`}
              component={PortfolioDetailsLeft}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/portfolio-filterable"}`}
              component={PortfolioFilterable}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/portfolio-ajax-loading"}`}
              component={PortfolioAjaxLoading}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/portfolio-masonry-wide"}`}
              component={PortfolioMasonryWide}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/portfolio-masonry-boxed"}`}
              component={PortfolioMasonryBoxed}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/portfolio-metro-boxed"}`}
              component={PortfolioMetroBoxed}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/portfolio-metro-wide"}`}
              component={PortfolioMetroWide}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/portfolio"}`}
              component={PortfolioWithCaption}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/portfolio-grid-wide"}`}
              component={PortfolioGridWide}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/portfolio-grid-boxed"}`}
              component={PortfolioGridBoxed}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/career"}`}
              component={Career}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/coming-soon"}`}
              component={ComingSoon}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/maintenance"}`}
              component={Maintenance}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/team-carousel"}`}
              component={TeamCarousel}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/team"}`}
              component={TeamGrid}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/pricing-table-two"}`}
              component={PricingTableTwo}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/pricing-table"}`}
              component={PricingTableOne}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/service-modern"}`}
              component={ServiceModern}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/service-classic"}`}
              component={ServiceClassic}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/contact"}`}
              component={ContactClassic}
            />

            <Route
              path={`${process.env.PUBLIC_URL + "/sitemap"}`}
              component={Sitemap}
            />

            <Route
              path={`${process.env.PUBLIC_URL + "/thank-you"}`}
              component={ThankYou}
            />
            
             <Route
              path={`${process.env.PUBLIC_URL + "/thank-you-influencer"}`}
              component={ThankYou}
            />

            <Route
              path={`${process.env.PUBLIC_URL + "/about-four"}`}
              component={AboutPageFour}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/about-three"}`}
              component={AboutPageThree}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/about-two"}`}
              component={AboutPageTwo}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/about-one"}`}
              component={AboutPageOne}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/brands"}`}
              component={BrandPage}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/influencers"}`}
              component={InfluencerPage}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/joinform"}`}
              component={JoinForm}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/about-im"}`}
              component={AboutIM}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/element/list"}`}
              component={ElementList}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/element/typed-text"}`}
              component={ElementTypedText}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/element/button"}`}
              component={ElementButton}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/element/accordion"}`}
              component={ElementAccordion}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/element/message-box"}`}
              component={ElementMessageBox}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/element/popup-video"}`}
              component={ElementPopupVideo}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/element/gallery"}`}
              component={ElementGallery}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/element/lightweight-slider"}`}
              component={ElementLightweightSlider}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/element/gradation"}`}
              component={ElementGradation}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/element/social-icon"}`}
              component={ElementSocialIcons}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/element/map"}`}
              component={ElementMaps}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/element/form"}`}
              component={ElementForms}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/element/tab"}`}
              component={ElementTabs}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/element/testimonial"}`}
              component={ElementTestimonial}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/element/icon-box"}`}
              component={ElementIconBox}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/element/progressbar"}`}
              component={ElementProgressbar}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/element/counter"}`}
              component={ElementCounter}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/element/countdown-clock"}`}
              component={ElementCountdownClock}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/element/instagram-feed"}`}
              component={ElementInstagramFeed}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/element/call-to-action"}`}
              component={ElementCallToAction}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/element/pricing-table"}`}
              component={ElementPricingTable}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/element/team-member"}`}
              component={ElementTeamMember}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/element/carousel-slider"}`}
              component={ElementCarouselSlider}
            />
            <Route
              path={`${
                process.env.PUBLIC_URL + "/element/flexible-image-slider"
              }`}
              component={ElementFlexibleImageSlider}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/element/client-logo"}`}
              component={ElementClientLogo}
            />
            <Route
              path={`${
                process.env.PUBLIC_URL + "/home-vertical-slide-portfolio"
              }`}
              component={HomeVerticalSlidePortfolio}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/home-minimal-portfolio"}`}
              component={HomeMinimalPortfolio}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/home-creative-portfolio"}`}
              component={HomeCreativePortfolio}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/home-revolutionary"}`}
              component={HomeRevolutionary}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/home-authentic-studio"}`}
              component={HomeAuthenticStudio}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/home-freelancer"}`}
              component={HomeFreelancer}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/home-minimal-agency"}`}
              component={HomeMinimalAgency}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/home-architecture"}`}
              component={HomeArchitecture}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/home-design-studio"}`}
              component={HomeDesignStudio}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/home-digital-agency"}`}
              component={HomeDigitalAgency}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/home-creative-agency"}`}
              component={HomeCreativeAgency}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/home-business"}`}
              component={HomeBusiness}
            />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route
              path={`${process.env.PUBLIC_URL + "/"}`}
              component={HomeBusiness}
            />
            <Route exact component={Error} />
          </Switch>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
};

export default App;
